<template>
  <v-container fluid>
    <PageHeaderSlot :showBottomLeftAndRightRow="true" :showBackButton="true" :isLoading="submitLoading">
    </PageHeaderSlot>
    <v-row class="mt-6">
      <v-col cols="12">
        <h3>{{ $validate.DataValid(id) ? '報銷費用詳細' : '新增報銷申請' }}</h3>
      </v-col>
      <v-col cols="12">
        <v-form ref="claim-form" @submit.prevent="formSubmit">
          <v-card class="mb-8">
            <v-card-text>
              <v-row>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="select"
                    :inputValue.sync="staff_id"
                    label="員工"
                    :required="true"
                    :options="staffList"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl inputType="string" :inputValue.sync="name" label="報銷項目名稱" :required="true" />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl inputType="datePicker" :inputValue.sync="date" label="日期" :required="true" />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="select"
                    :inputValue.sync="claim_type"
                    label="報銷類型"
                    :required="true"
                    :options="claimTypeList"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="number"
                    :inputValue.sync="amount"
                    label="金額"
                    :required="true"
                    :validateFloating="true"
                    :inputFloatingOnly="true"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl inputType="string" :inputValue.sync="remark" label="備註" />
                </v-col>
                <v-col md="6" cols="12" v-show="$validate.DataValid(id)">
                  <FormControl
                    inputType="select"
                    :inputValue.sync="approve_status"
                    label="申請狀態"
                    :required="$validate.DataValid(id)"
                    :options="approveStatusList"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h3>報銷收據</h3>
                  <ImageUploader
                    :singleImage="false"
                    :imgList.sync="receipt"
                    :uploadText="'上載報銷收據'"
                    :acceptFormat="'imageWithPDF'"
                    :disableUpload="submitLoading"
                  ></ImageUploader>
                  <div class="error-msg mt-3">{{ receiptErrorMsg }}</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="submitLoading">
            儲存
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
  <!--  <v-row>
      <v-col cols="12">
         <h3 v-if="id === undefined">新增報銷申請</h3>
         <h3 v-else>報銷費用詳細</h3>
      </v-col>
      <v-col cols="12">
         <v-form ref="claim-form" @submit.prevent="formSubmit">
            <v-row>
               <v-col md="12" cols="12">
                  <v-card>
                     <v-card-text>
                        <v-row>
                           <v-col md="6" cols="12">
                              <FormControl inputType="datePicker" :inputValue.sync="create_date" label="提交日期" :required="true" />
                           </v-col>
                           <v-col md="6" cols="12">
                              <FormControl inputType="select" :inputValue.sync="staff" label="員工姓名" :required="true" :options="dataOptions.staff" />
                           </v-col>
                           <v-col md="6" cols="12">
                              <FormControl inputType="string" :inputValue.sync="item" label="新增項目" :required="true" />
                           </v-col>
                           <v-col md="6" cols="12">
                              <FormControl inputType="number" :inputValue.sync="amount" label="金額" :required="true" />
                           </v-col>
                           <v-col md="6" cols="12" v-if="id !== undefined">
                              <FormControl inputType="select" :inputValue.sync="status" label="申請狀態" :options="options.approve_status" />
                           </v-col>
                           <v-col md="6" cols="12">
                              <FormControl inputType="string" :inputValue.sync="remark" label="備註" />
                           </v-col>
                           <v-col md="6" cols="12">
                              <FormButton 
                                 inputType="string" 
                                 :inputValue.sync="proofFile" 
                                 label="上載圖片" 
                                 :buttonLink="proof_url" 
                                 buttonText="查看" 
                                 :allowEdit="id === undefined" />
                           </v-col>


                        </v-row>
                     </v-card-text>
                  </v-card>
               </v-col>
               <v-col md="12" cols="12">
                  <v-btn depressed color="primary accent-4" class="white--text" type="submit">儲存</v-btn>
               </v-col>
            </v-row>
         </v-form>
      </v-col>
   </v-row> -->
</template>

<script>
import { mapActions } from 'vuex'
import FormControl from '@/components/form/FormControl'
import ImageUploader from '@/components/ImageUploader.vue'

export default {
  name: 'AdminClaimDetail',
  components: {
    FormControl,
    ImageUploader,
  },
  data() {
    return {
      submitLoading: false,
      submitSuccess: false,
      staff_id: '',
      name: '',
      claim_type: '',
      date: '',
      amount: '',
      remark: '',
      receipt: [],
      approve_status: '',

      staffList: [],
      receiptErrorMsg: '',
    }
  },
  computed: {
    id: function () {
      return this.$route.params.id
    },
    claimTypeList: function () {
      return this.$claimTypeList
    },
    approveStatusList: function () {
      return this.$approveStatusList
    },
  },
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog']),
    async getStaffList() {
      try {
        const payload = {
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_staff', payload)
        this.$func.log('-----Get Staff List-----')
        this.$func.log(result)

        const temp = []

        for (let i = 0; i < result.data.length; i++) {
          const resultData = result.data[i]

          temp.push({
            value: resultData.id,
            text: `${resultData.name} (${resultData.email})`,
          })
        }

        this.staffList = temp
      } catch (error) {
        this.$func.log('-----Get Staff fail-----')
        this.$func.log(error)

        let msg = ''

        if (error.data === 'no permission') {
          msg = '沒有權限'
        }

        if (this.$validate.DataValid(msg)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: msg,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }
      }
    },
    async getClaimDetail() {
      let claimError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.id),
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_claim_data_by_id', payload)
        this.$func.log('-----Get Claim Detail-----')
        this.$func.log(result)

        this.staff_id = result.staff_id
        this.name = result.name
        this.claim_type = result.claim_type
        this.date = result.date
        this.amount = result.amount
        this.receipt = result.receipt
        this.approve_status = result.approve_status
        this.remark = result.remark

        this.$store.dispatch('toggleLoadingPage', false)
      } catch (error) {
        this.$func.log('-----Get Claim Detail Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          claimError = true
          message = '沒有權限'
        } else {
          claimError = true
        }
      }

      if (claimError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'adminClaim',
        })
      }
    },
    async formSubmit() {
      if (!this.submitLoading) {
        this.submitLoading = true
        this.receiptErrorMsg = ''

        const isValid = this.$refs['claim-form'].validate()
        if (this.receipt.length <= 0) {
          this.receiptErrorMsg = '請至少上傳一個檔案'
        }

        if (!isValid | this.$validate.DataValid(this.receiptErrorMsg)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          this.submitLoading = false
          return
        }

        try {
          const payload = {
            data_type: 'claim_data',
            status: 'active',
            data_status: 'active',
            verify_token: this.getAdminToken(),
            staff_id: parseInt(this.staff_id),
            name: this.name,
            date: this.date,
            claim_type: this.claim_type,
            amount: parseFloat(this.amount),
            remark: this.remark,
            receipt: this.receipt,
            approve_status: this.$validate.DataValid(this.id) ? this.approve_status : 'pending',
          }

          let key = ''

          if (this.$validate.DataValid(this.id)) {
            key = 'cms_update_claim'
            payload.id = parseInt(this.id)
          } else {
            key = 'cms_add_claim'
          }

          const result = await this.$XHR.api(key, payload)
          this.$func.log('--- update claim data ---')
          this.$func.log(result)

          this.submitSuccess = true

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: { name: 'adminClaim' },
          })
          this.setShowDialog(true)
        } catch (error) {
          this.$func.log('--- update claim data fail ---')
          this.$func.log(error)

          let msg = '儲存失敗'

          if (error.data === 'admin verification fail') {
            msg = ''
            this.forceLogout()
          } else if (error.data === 'no permission') {
            msg = '沒有權限'
          } else if (error.data === 'staff not found') {
            msg = '員工不存在'
          } else if (error.data === 'claim record not found') {
            msg = '沒有報銷記錄'
          }

          this.submitLoading = false
          this.submitSuccess = false
          this.setDialogMessage({
            message: msg,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.getStaffList()

      if (!this.$validate.DataValid(this.id)) {
        this.$store.dispatch('toggleLoadingPage', false)
      } else {
        this.getClaimDetail()
      }
    }
  },
  async beforeDestroy() {
    let removeList = []
    if (this.$store.getters.removedImageList.length > 0) {
      removeList = removeList.concat(this.$store.getters.removedImageList)
    }

    /* if (!this.submitSuccess) {
      removeList = removeList.concat(this.medical_certificate)
    } */

    if (removeList.length > 0) {
      const removeImage = await this.$XHR.api('cms_remove_media', {
        verify_token: this.getAdminToken(),
        remove_image_list: this.$store.getters.removedImageList,
      })

      this.$func.log('--- remove image success ---')
      this.$func.log(removeImage)
    }
  },
}
</script>